module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","es"],"defaultLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a9742f15dd4fdc5aaafa4c2063a9b6b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K6WRF8C","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"baubocare","accessToken":"MC5YLW5US1JBQUFDUUFnYlRK.77-977-9F--_vQI977-977-9JHfvv71977-977-9Ru-_vSFW77-9FGYs77-9VhF2Xu-_vW9sVlI","schemas":{"blog":{"Main":{"uid":{"type":"UID","config":{"label":"uid"}},"preview_img":{"type":"Image","config":{"constraint":{"width":350,"height":150},"thumbnails":[],"label":"preview_img"}},"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"title"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"content"}},"related":{"type":"Group","config":{"fields":{"article":{"type":"Link","config":{"select":"document","customtypes":["blog"],"label":"article"}}},"label":"related"}}}}},"lang":"*"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
